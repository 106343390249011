<script setup lang="ts">
definePageMeta({ middleware: 'marketing' });

useHead({
  title: 'Log In',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: 'Log in to Pilot Car Loads to find or post a load.',
    },
  ],
});
</script>

<template>
  <div class="bg-gray-100">
    <container>
      <section>
        <div class="flex min-h-screen flex-col justify-center pb-16 md:pb-8">
          <div class="sm:mx-auto sm:w-full sm:max-w-md md:-mt-36">
            <h1 class="mt-6 text-center text-3xl font-extrabold leading-9 text-gray-900">
              Log in to your Pilot Car Loads account
            </h1>
          </div>

          <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
              <marketing-login-form />
            </div>
            <div class="mt-6">
              <nuxt-link
                to="/register"
                class="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-sm font-medium leading-6 text-teal-700 shadow transition duration-150 ease-in-out hover:text-teal-500 focus:border-teal-300 focus:outline-none"
              >
                Haven't registered yet? Sign up for free.
              </nuxt-link>
            </div>
          </div>
        </div>
      </section>
    </container>
  </div>
</template>
