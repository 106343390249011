<script setup lang="ts">
import { email as emailValidator, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const submitting = ref(false);
const email = ref('');
const password = ref('');
const serverErrors = ref({
  general: null,
  username: null,
  password: null,
});

const { userData } = storeToRefs(useUserStore());

const userType = computed(() => userData.value?.type);

const validations = {
  email: {
    required,
    email: emailValidator,
  },
  password: {
    required,
  },
};

const $v = useVuelidate(validations, { email, password });

const { getFieldErrorMessages } = useFormHandling();

const { logIn } = useAuthStore();

async function submit() {
  clearErrors();

  $v.value.$touch();

  if ($v.value.$invalid) {
    return;
  }

  try {
    submitting.value = true;

    await logIn({ email: email.value, password: password.value });

    const postLoginPage = userType.value === 'shipper' ? '/dashboard/post-load' : '/dashboard/loads';

    navigateTo(postLoginPage);
  } catch (e) {
    const defaultError = ['An error occurred. Please try again.'];

    if (e === undefined || e === null) {
      serverErrors.value.general = defaultError;
      return;
    }

    const error = e.response;

    if (error.status === 422) {
      // Invalid input
      const errorMessages = getFieldErrorMessages(error.data.detail);

      Object.entries(errorMessages).forEach(([field, value]) => {
        if (field in serverErrors.value) {
          serverErrors.value[field] = value;
        }
      });
    } else if (error.status >= 400 && error.status < 500 && error.data.detail) {
      // Correct input but invalid login info
      serverErrors.value.general = [error.data.detail];
    } else {
      // Server error
      serverErrors.value.general = defaultError;
    }
  } finally {
    submitting.value = false;
  }
}

function clearErrors() {
  serverErrors.value = {
    general: null,
    username: null,
    password: null,
  };
}
</script>

<template>
  <form
    action="#"
    method="POST"
  >
    <div>
      <label
        for="email"
        class="block select-none text-sm font-medium leading-5 text-gray-700"
      >
        Email
      </label>

      <div class="mt-1 rounded-md shadow-sm">
        <input
          id="email"
          v-model.trim="email"
          type="email"
          required
          class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 transition duration-150 ease-in-out placeholder:text-gray-400 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
          :class="{ 'border-red-500': $v.email.$error || serverErrors.username || serverErrors.general }"
        >
      </div>
    </div>

    <p
      v-if="serverErrors.username || $v.email.$error"
      class="text-sm text-red-500"
    >
      {{ serverErrors.username || 'You must enter a valid email' }}
    </p>

    <div class="mt-6">
      <label
        for="password"
        class="block select-none text-sm font-medium leading-5 text-gray-700"
      >
        Password
      </label>

      <div class="mt-1 rounded-md shadow-sm">
        <input
          id="password"
          v-model="password"
          type="password"
          required
          class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 transition duration-150 ease-in-out placeholder:text-gray-400 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
          :class="{ 'border-red-500': $v.password.$error || serverErrors.password || serverErrors.general }"
        >
      </div>
    </div>

    <div class="mt-2 flex justify-end">
      <div class="text-sm leading-5">
        <nuxt-link
          to="/forgot-password"
          class="font-medium text-teal-700 transition duration-150 ease-in-out hover:text-teal-500 focus:underline focus:outline-none"
        >
          Forgot password?
        </nuxt-link>
      </div>
    </div>

    <p
      v-if="serverErrors.password || $v.password.$error"
      class="text-sm text-red-500"
    >
      {{ serverErrors.password || 'You must enter a valid password' }}
    </p>

    <template v-if="serverErrors.general">
      <p
        v-for="(error, idx) of serverErrors.general"
        :key="idx"
        class="text-sm text-red-500"
      >
        {{ error }}
      </p>
    </template>

    <div class="mt-6">
      <span class="block w-full rounded-md shadow-sm">
        <button
          type="submit"
          class="flex w-full items-center justify-center rounded-md border border-transparent bg-teal-700 px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out hover:bg-teal-600 focus:border-teal-800 focus:outline-none active:bg-teal-800"
          @click.prevent="submit"
        >
          Log in
          <loading-spinner
            v-if="submitting"
            class="size-5 text-white"
          />
        </button>
      </span>
    </div>
  </form>
</template>
